import './App.css';
import EmployeeNum from './Screens/EmployeeNum';
import Scanner from './Screens/Scanner';

function App() {
  return (
    <div className="App">
      <Scanner/>
    </div>
  );
}

export default App;
