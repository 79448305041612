import React, { useEffect } from 'react';
import Quagga from 'quagga';
import Swal from 'sweetalert2';
import '../CSS/Scanner.css';

const Scanner = ({ history }) => {
    useEffect(() => {
        Quagga.init({
            inputStream: {
                name: "Live",
                type: "LiveStream",
                target: document.getElementById('scanner-container'),
                constraints: {
                    facingMode: "environment",
                    width: window.innerWidth,
                    height: window.innerHeight, 
                                },
                area: { 
                    top: "10%",    
                    right: "10%",  
                    left: "10%",   
                    bottom: "10%"
                }
            },
            locator: {
                patchSize: "medium",
                halfSample: false
            },
            numOfWorkers: navigator.hardwareConcurrency || 4,
            frequency: 10,
            
            decoder: {
                readers: [
                    // "code_128_reader",
                    // "ean_reader",
                    // "ean_8_reader",
                    // "code_39_reader",
                    // "code_39_vin_reader",
                    // "codabar_reader",
                    // "upc_reader",
                    // "upc_e_reader",
                    "i2of5_reader" // This is the Interleaved 2 of 5 reader
                ]
            },
            
            locate: true,
            debug: {
                drawBoundingBox: true,
                showFrequency: true,
                drawScanline: true,
                showPattern: true
            }
            
        }, (err) => {
            if (err) {
                console.error(err);
                return;
            }
            Quagga.start();
            toggleFlashlight(true);
        });

        Quagga.onDetected((data) => {
            Quagga.stop();  // Stop Quagga after detecting a barcode
            toggleFlashlight(false);
        
            // Extracting the first 8 digits from the barcode
            const firstEightDigits = data.codeResult.code.slice(0, 8);
            const formattedBarcode = `${firstEightDigits.slice(0, 3)}.${firstEightDigits.slice(3, 6)}.${firstEightDigits.slice(6)}`;
        
            Swal.fire({
                title: 'מוצר נסרק בהצלחה',
                html: `<b>${formattedBarcode}</b>`,
                icon: 'success',
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: 'סריקה נוספת',
                denyButtonText: 'לצפייה באתר איקאה',
                denyButtonClass: 'swal2-view-link-btn',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isDenied) {
                    const ikeaLink = `https://www.ikea.com/il/he/search/?q=${firstEightDigits}`;
                    window.open(ikeaLink, '_blank');
                } else if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        });
                        

        return () => {
            Quagga.offDetected();
            Quagga.stop();
        };
    }, []);


    function formatBarcode(input) {
        const firstEight = input.slice(0, 8);
        return `${firstEight.slice(0, 3)}.${firstEight.slice(3, 6)}.${firstEight.slice(6)}`;
    }
    
    const toggleFlashlight = (on) => {
        const track = Quagga.CameraAccess.getActiveTrack();
        if (track) {
            let settings = track.getSettings();
            let constraints = track.getConstraints();
    
            // Check if torch is supported
            if (settings.torch) {
                constraints.advanced = constraints.advanced || [];
                constraints.advanced.push({ torch: on });
    
                track.applyConstraints(constraints)
                .then(() => {
                    console.log(`Torch is ${on ? 'on' : 'off'}.`);
                })
                .catch(e => {
                    console.error('Could not apply constraints: ', e);
                });
            } else {
                console.log('Torch not supported by this device.');
            }
        }
    };
    
    const goBack = () => {
        // Assumes you're using React Router for navigation
        history.goBack();
    };

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh' }}>
            <div id="scanner-container" style={{ width: '100%', height: 'calc(100% - 100px)' }} />
            <div style={{ position: 'absolute', bottom: '50px', width: '100%', textAlign: 'center' }}>
                <button onClick={toggleFlashlight} style={{ marginRight: '20px' }}>Toggle Flashlight</button>
                <button onClick={goBack}>Go Back</button>
            </div>
        </div>
    );
};

export default Scanner;
